<script>
/**
 * Dropdown Default component
 */
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    emitEdit() {
      this.$emit('edit', true);
    },    
    emitExclude() {
      this.$emit('exclude', true);
    }
  }
};
</script>

<template>
  <b-dropdown 
          class="d-inline-block w-100"
          right
          menu-class="dropdown-menu-end" variant="outline-primary">
    <template v-slot:button-content>
      <i class="mdi mdi-dots-vertical"></i>
    </template>
    <b-dropdown-item @click="emitEdit" href="javascript: void(0);"
      >Editar</b-dropdown-item
    >
    <b-dropdown-item @click="emitExclude" href="javascript: void(0);"
      >Excluir</b-dropdown-item
    >
  </b-dropdown>
</template>