<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DropdownDefault from "@/components/dropdown-default";
import Multiselect from "vue-multiselect";

import {
  authorMethods,
  courseMethods,
  categoryMethods,
  notificationMethods,
} from "@/state/helpers";

/**
 * Courses component
 */
export default {
  page: {
    title: "Gerenciar Cursos",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    DropdownDefault,
    Multiselect
  },
  data() {
    return {
      tableData: [],
      title: "Gerenciar Cursos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Cursos",
          active: true,
        },
      ],      
      form: {
        status: "",
        author: "",
        category: "",
        price: "",
      },
      authors: [],
      categories: [],
      prices: [{
          key: "Maior para o Menor",
          value: 'desc',
        },
        {
          key: "Menor para o Maior",
          value: 'asc',
        },
      ],
      status: [
        {
          key: "Ativo",
          value: true,
        },
        {
          key: "Inativo",
          value: false,
        },
      ],
      tableHighlighted: 0,
      totalRows: 1,
      rows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        title: "Título",
        category: "Categoria",
        author: "Autor",
        total_students: "Número de Estudantes",
        status: "Status",
        actions: "Ações",
      },  
      loading: false,
    }; 
  },
  computed: {    
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id },
        { key: "title", sortable: true, label: this.fieldAliases.title },
        { key: "category", sortable: true, label: this.fieldAliases.category },
        { key: "author", sortable: true, label: this.fieldAliases.author },
        { key: "total_students", sortable: true, label: this.fieldAliases.total_students },
        { key: "status", sortable: true, label: this.fieldAliases.status },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  created() {
    this.clearNotifications();
    this.loadAll();
    this.loadData();
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  methods: {
    ...authorMethods,
    ...categoryMethods,
    ...courseMethods,
    ...notificationMethods,
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },    

    async loadAll(formSearch = null) {
      this.loading = true;
      const registros = await this.getAllCourse(formSearch);
      this.tableData = registros?.data || [];
      this.loading = false;
      this.totalRows = this.tableData.length;
      this.rows = this.tableData.length;
    },

    async loadData() {
      await Promise.all([
        this.loadAuthors(),
        this.loadCategories(),
      ]);
    },

    async loadAuthors() {
      this.showModalAuthor = false;
      let registros = await this.getAllAuthor();
      this.authors = registros?.data || [];
    },

    async loadCategories() {
      this.showModalCategory = false;
      let registros = await this.getAllCategory();
      this.categories = registros?.data || [];
    },

    async filterRegisters() {
      this.loadAll(this.form)
    },

    handleNewCad() {
      this.$router.push('/course/create');
    },

    edit(course) {
      this.$router.push(`/course/edit/${course.id}`);
    },

    async exclude(course) {      
      await this.delete(course.id);
      this.loadAll();
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :addText="'Adicionar Novo Curso'" @newCad="handleNewCad" />
    <b-alert 
      v-if="notification.message" 
      show 
      variant="warning" 
      dismissible      
      @dismissed="clearNotifications()"
      >
      {{ notification.message }}
    </b-alert>
    <div class="row">
      <div class="col-12">
        <div class="card">        
          <div class="card-body">
            <h4 class="card-title">Lista de Cursos</h4>

            <div class="mb-5 row mt-4">
              <div class="col-xl">
                <label>Categorias</label>
                <multiselect
                  v-model="form.category"
                  :options="categories"
                  :select-label="''"
                  :deselect-label="''"
                  placeholder="Categoria do curso"
                  label="title"
                  track-by="id"
                >
                  <span slot="noOptions">Não foram encontradas opções</span>
                </multiselect>
              </div>
              <div class="col-xl">
                <label>Status</label>
                <multiselect
                  v-model="form.status"
                  :options="status"
                  :select-label="''"
                  :deselect-label="''"
                  placeholder="Status do curso"
                  label="key"
                  track-by="value"
                >
                  <span slot="noOptions">Não foram encontradas opções</span>
                </multiselect>
              </div>
              <div class="col-xl">
                <label>Autor</label>
                <multiselect
                  v-model="form.author"
                  :options="authors"
                  :select-label="''"
                  :deselect-label="''"
                  placeholder="Autor do curso"
                  label="name"
                  track-by="id"
                >
                  <span slot="noOptions">Não foram encontradas opções</span>
                </multiselect>
              </div>
              <div class="col-xl">
                <label>Preço</label>
                <multiselect
                  v-model="form.price"
                  :options="prices"
                  :select-label="''"
                  :deselect-label="''"
                  placeholder="Valor do curso"
                  label="key"
                  track-by="value"
                >
                  <span slot="noOptions">Não foram encontradas opções</span>
                </multiselect>
              </div>
              <div class="col-xl">
                <label class="text-white">..</label>
                <b-button class="w-100" variant="primary" @click="filterRegisters">Filtrar</b-button>
              </div>
            </div>

            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-spinner
                v-if="loading"
                label="Spinning"
                variant="info"
                class="m-1"
              ></b-spinner>
              <b-table              
                v-if="!loading"
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="obj">
                  <div class="actions-cell">
                    <DropdownDefault @edit="edit(obj.item)" @exclude="exclude(obj.item)"></DropdownDefault>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>